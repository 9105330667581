import {Button} from 'antd'
import {join} from 'hopedove-dom/url'
import http from '@/biz/http.mjs'
import useAlert from '@/biz/useAlert.jsx'
import runAsync from '@/script/runAsync.mjs'
import {useMapContext} from '@/components/MindMap/index.mjs'

export default function PropertiesPaneButtonExportModel({
    format = '',
    global = false,
    type,
    ...props
}) {
    const alert = useAlert()
    const map = useMapContext()
    const selectedNodes = map.useSelectedNodes()

    if (! (
        1 === selectedNodes.size &&
        'SA_MAP' === map.data.mapTypeCode
    )) {
        return null
    }

    const name = (() => {
        const n = {
            bdlogic: '业务事务能力',
            bdrule: '业务规则',
            bdsummary: '用户场景',
            bdterm: '业务术语',
            bf: '业务流程',
            bm: '业务',
            des: '产品需求',
            fm: '功能',
        }[type]

        return {
            '': `${n}模型`,
            'excel': `${n}清单Excel版`
        }[format]
    })()

    const handleClick = async () => {
        const url = {
            bdrule: '/prd/export/sa/bm/bdrule',
            bdsummary: '/prd/export/sa/bm/bdsummary',
            bdterm: '/prd/export/sa/bm/bdterm',
            bf: '/prd/export/sa/bm/bf',
            bm: '/prd/export/sa/bm',
            des: '/prd/export/despr',
            fm: '/prd/export/sa/sf',
            bdlogic: '/prd/export/sa/bm/bdlogic',
        }[type]

        const [node] = selectedNodes
        const codeProp = `${type}Code`
        const idProp = `${type}Id`

        const {
            [codeProp]: code,
            pkid,
            prjId,
            sVer,
        } = node.data

        const args = {prjId, sVer}

        if (! global) {
            Object.assign(args, {
                [codeProp]: code,
                [idProp]: pkid,
            })
        }

        await runAsync(
            () => http.post(join(url, format), args),
            {action: `导出${name}`}
        )

        alert.generatingFile()
    }

    return (
        <Button
            onClick={handleClick}
            {...props}
        >
            导出{name}
        </Button>
    )
}
